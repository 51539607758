<template>
  <section class="main_bestitem best_mar_set wrapper" style="aspect-ratio: 1920/770;" v-if="(goodsList||[]).length > 0">
    <div class="main_contit">
      <strong>{{ titleName }}</strong>
    </div>
    <goods-list :goods-list="goodsList" :inflow-method="inflowMethod"/>
    <nuxt-link :to="moveLink" class="more_link" :aria-label="`${titleName} 더보기 이동`">view all</nuxt-link>
  </section>
  <section class="main_bestitem best_mar_set wrapper" style="aspect-ratio: 1920/770;" v-else-if="!initData">
    <h4 class="main_contit"></h4>
  </section>
</template>

<script>
import GoodsList from "@/components/main/desktop/BestGoodsList.vue";

export default {
  components: {
    GoodsList,
  },
  props: {
    category: {
      type: Object,
      default: () => Array
    },
    menutitle: String
  },
  data() {
    return{
      goodsList: [],
      inflowMethod: undefined,
      initData: false
    }
  },
  async fetch() {
    await this.fetchGoodsList();
    this.initData = true;
  },
  computed:{
    titleName() {
      let name = "BEST ITEM";
      // console.log(this.menutitle)
      if (this.menutitle == "BEST") {
        name = "Best Item";
      } else if (this.menutitle == "NEW") {
        name = "New Arrivals";
      } else if (this.menutitle == "24SO") {
        //name = "Season off"
        name = "2024 Summer";
      }
      return name;
    },
    moveLink() {
      let link = "/main/best";
      // console.log(this.menutitle)
      if (this.menutitle == "BEST") {
        link = "/main/best";
      } else if (this.menutitle == "NEW") {
        link = "/main/new";
        //link = "/promotion/609";
      } else if (this.menutitle == "24SO") {
        link = "/promotion/597"
      }
      return link;
    }
  },

  methods: {
    async fetchGoodsList() {
      if(this.category.categoryNo == '800900102') { //NEW
        this.goodsList = await this.$axios.$get(`/api/goods/new`);
        this.inflowMethod = "MAINNEW";
      } else if(this.category.categoryNo == '800900103') { //BEST
        let page = 1;
        const { list } = await this.$axios.$get(`/api/goods/mainbest/${page}`);
        this.goodsList = list;
        this.inflowMethod = "MAINBEST";
      } else if (this.category.categoryNo == '800900999') {
        //임시전시 (실제 존재하지 않는 전시임 585 기획전상품 가져오기)
        try {
          let exhibition = await this.$axios.$get(`/api/exhibition/609`, {params: {nohit : "Y"}});
          if(exhibition != null && exhibition.themes.length > 0){
            exhibition.themes.forEach(theme => {
              this.goodsList.push(...theme.goodsList);
            })
          }
        } catch (e) {}
      } else {
        this.goodsList = await this.$axios.$get(`/api/main/${this.category.categoryNo}/goods`);
      }

      if(this.$device.isDesktop){
        this.goodsList = this.goodsList.splice(0,4);
      } else {
        this.goodsList = this.goodsList.splice(0,10);
      }
      // console.log('this.goodsList : ', this.goodsList);
    }
  }

};
</script>
