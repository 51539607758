<template>
  <div class="spec_wrap">
    <div class="theme_con" v-for="(banner,bannerIndex) in banners" :key="`banner-${bannerIndex}`">

      <!-- <div v-if="goodsCategory[bannerIndex] !== 800900107" >  -->
      <div>
        <div class="main_tit mgb10 wrapper dpf">
          <h3><strong v-html="banner.goodsTitle"></strong></h3>
          <!-- <p v-html="banner.bnrHtml"></p> -->
          <a :href="getUrl(banner.bnrLinkUrl)" class="more_btn">view all</a>
        </div>
        <div class="the_thumb spec_po" style="background-color: black;">
          <div data-aos="fade-left" data-aos-duration="500" style="background-color: white;">
            <video v-if="banner.bnrMobileVideoUrl" class="top-visual-video" muted loop autoplay playsinline webkit-playsinline style="position: unset;display:block;">
              <source :src="banner.bnrMobileVideoUrl" type="video/mp4" />
            </video>
            <img v-else :src="getImageUrlRS(banner.bnrMobileImgPath, 800)" alt="Special 배너 썸네일">
          </div>
          
          <div class="spec_bnr_info">
            <div :id="`special-text-box-anchor-${bannerIndex}`" style="overflow: hidden;">
              <div :data-aos-anchor="`#special-text-box-anchor-${bannerIndex}`" data-aos="slide-up" data-aos-offset="200" data-aos-delay="0" data-aos-duration="1000">
                <div class="bn_txt_wrap">
                  <p class="ttl">{{ banner.bnrText }}</p>
                </div>
                <div class="bn_txt_wrap">
                  <p class="sub mgt10" v-show="banner.bnrHtml" v-html-custom="banner.bnrHtml"></p>
                </div>
              </div>
            </div>

            <div class="spec_bnr_btn" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="500" data-aos-duration="500">
              <a :href="getUrl(banner.bnrBtnUrl1)" v-if="banner.bnrBtnCnt >= 1">{{ banner.bnrBtnNm1 }}</a>
              <a :href="getUrl(banner.bnrBtnUrl2)" v-if="banner.bnrBtnCnt >= 2">{{ banner.bnrBtnNm2 }}</a>
            </div>
          </div>
        </div>
        <div class="the_swiper mgt20" v-if="(banner.goodsList || []).length > 0">
          <div class="scrollbar_proli wrapper">
            <SpecialList :goods-list="banner.goodsList"></SpecialList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SpecialList from "@/components/main/mobile/SpecialList.vue";
export default {
    components: {
        SpecialList,
    },
    props: {
        category: {
            type:Object,
            default: () => Array
        },
        callbackSpecialFetchComplete: {
          type: Function,
          default: () => undefined
        }
    },
    data() {
    return{
      // goodsList: [],
      banners: [],
      //goodsCategory:[800900105, 800900106, 800900107],
      //limitBannerLength : 3
    }
  },

  async fetch() {
    await this.fetchBanners();
    if(this.callbackSpecialFetchComplete) {
      this.callbackSpecialFetchComplete();
    }
  },
  computed:{
  },
  methods: {
    async fetchBanners() {
      let banners = await this.$axios.$get(`/api/main/${this.category.categoryNo}/banners`);
      let goodsList = await this.$axios.$get(`/api/main/${this.category.categoryNo}/goods`);
      banners = banners.filter(banner => !banner.goodsId);
      
      if (this.$device.isMobile) {
        banners = banners.filter(banner => {
          return banner.bnrWebMobileGbCd === '00' || banner.bnrWebMobileGbCd === '20'
        })
      } else {
        banners = banners.filter(banner => {
          return banner.bnrWebMobileGbCd === '00' || banner.bnrWebMobileGbCd === '10'
        })
      }

      banners.forEach(banner => {
        banner.goodsList = goodsList.filter(goods => (goods.dispBnrNoList||[]).indexOf(banner.dispBnrNo) > -1 );
      });

      this.banners = banners;
      /*
      if(this.banners && this.banners.length > this.limitBannerLength) {
        this.banners = this.banners.slice(0, this.limitBannerLength);
      }
      */
    },

    customHref (banner) {
      if(!banner) return '';

      return !(banner.bnrLinkUrl || '' ).trim() || banner.bnrLinkUrl == '#' ? 'javascript:;': banner.bnrLinkUrl;
    },
  }
}
</script>
