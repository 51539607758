<template>
  <div>
    <!--<div class="main_videowrap" :class="{ dp_none: !isVisibleVideo }">
      <div class="video_box">
        <!-- <video autoplay="" loop="" muted="" playsinline="" width="100%" height="100%" :style="$device.isIos ? 'object-fit: cover' : 'object-fit: fill'">
        <video autoplay="" loop="" muted="" playsinline="" preload="auto" width="100%" height="100%" :style="$device.isIos ? 'object-fit: cover' : 'object-fit: fill'">
          <!-- <source src="https://vod.lottegfr.co.kr/encodeFile/14525/2022/05/31/vzBzCpgljioKFwt3tFDParaOWB19lB7r.mp4" type="video/mp4" />
          <!--<source src="https://vod.lottegfr.co.kr/encodeFile/14525/2022/10/19/p7rb8sZbh2J3bnu7xH3Y94xOS9YKxjxF.mp4#t=0.001" type="video/mp4" />
          <source src="https://vod.lottegfr.co.kr/encodeFile/14525/2022/10/19/LNCxbN5GaBQcqbRLHYzC8EU4o0H46Q8D.mp4#t=0.001" type="video/mp4" />
        </video>
      </div>
      <div class="video_txt" style="display: none">
        <strong>THE <br />ORIGINAL <br />WIND BREAKER</strong>
      </div>
      <div class="float_down bounce-animation"></div>
    </div>-->
    <!--//main_videowrap-->
    <div class="main_conwrap sticky sub_para05" style="margin-top: 0px;">
      <Snow v-if="snowYn == 'Y'"></Snow>
      <Rain v-if="rainYn == 'Y'"></Rain>
      <VisualBanner :category="category('800900101')"></VisualBanner>
      <component :is="`BestGoods`" :category="category('800900102')" :menutitle="`NEW`"></component>
      <!--component :is="`BestGoods`" :category="{ categoryNo : 800900999 }" :menutitle="`NEW`"></component-->
      <!-- <People :category="category('800900109')"></People> -->
      <Special :category="category('800900104')" :callbackSpecialFetchComplete="callbackSpecialFetchComplete"></Special>
      <!-- <ImgBanner :category="category('800900108')"></ImgBanner> -->
      <!-- <component :is="`BestGoods`" :category="category('800900103')" :menutitle="`BEST`"></component> -->

      <TodayCardSection></TodayCardSection>

      <!--div class="main_bestreview mgt70 wrapper">
        <div class="main_tit dpf">
          <h3>Best Review</h3>
          <nuxt-link to="/main/review" class="more_btn">view all</nuxt-link>
        </div>
        <div class="rviw_licon">
           --<img src="/mobile/images/pic_rvwlicon.jpg" class="rviw_img" alt=""/> 해당라인 주석--
          <div class="crema-reviews" data-widget-id="29"></div>
        </div>
      </div-->
      <!--//best_review-->
      <!-- cre.ma / 리뷰 작성 유도 팝업 / 스크립트를 수정할 경우 연락주세요 (support@cre.ma) -->
      <div class="crema-popup"></div>
    </div>
  </div>
</template>


<script>
import People from "@/components/main/mobile/People.vue";
import VisualBanner from "@/components/main/mobile/VisualBanner.vue";
import Special from "@/components/main/mobile/Special.vue";
import BestGoods from "@/components/main/mobile/BestGoods.vue";
import ImgBanner from "@/components/main/mobile/ImgBanner.vue";
import Snow from "@/components/main/mobile/Snow"
import Rain from "@/components/main/mobile/Rain"
import TodayCardSection from "@/components/main/mobile/TodayCardSection.vue";
import ScrollRememberUtil from '@/assets/mixins/scroll-remember-util';

export default {
  components: {
    People,
    VisualBanner,
    Special,
    BestGoods,
    ImgBanner,
    Snow,
    Rain,
    TodayCardSection,
  },

  mixins: [ScrollRememberUtil],

  props: {
    categories: {
      type: Array,
    },
  },
  data: () => ({
    isVisibleVideo: true,
    snowYn: 'N',
    rainYn: 'N'
  }),

  async mounted() {
    window.addEventListener("scroll", this.handleVisibleVideo);
    this.snowYn = await this.$axios.$get("/api/main/snowstatus");
    this.rainYn = await this.$axios.$get("/api/main/rainstatus");
  },
  created() {
    this.initOnMaxScrollOptions({
      enableScrollMove: false,
    });
  },
  async activated() {
    this.snowYn = await this.$axios.$get("/api/main/snowstatus");
    this.rainYn = await this.$axios.$get("/api/main/rainstatus");
  },
  methods: {
    category(categoryNo) {
      return this.categories.find((x) => x.categoryNo == categoryNo);
    },
    handleVisibleVideo() {
      const screenHeight = window.innerHeight - 55;
      if ((window.scrollY || window.pageYOffset) > screenHeight) {
        this.isVisibleVideo = false;
      } else {
        this.isVisibleVideo = true;
      }
    },
    callbackSpecialFetchComplete() {
      //스페셜 Fetch가 끝나면 스크롤 move시키기
      this.initOnMaxScrollOptions({
        enableScrollMove: true,
        delayMs: 300
      });
      this.scrollMoveAction();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleVisibleVideo);
  },
};
</script>
