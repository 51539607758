<template>
 <div class="item_listwrap mgt50 wrapper" v-if="(goodsList||[]).length > 0">
   <div class="main_tit">
      <h3>{{ titleName }}</h3>
    </div>
    <goods-list :goods-list="goodsList" :inflow-method="inflowMethod"/>
    <nuxt-link :to="moveLink" class="more_link more_btn" :aria-label="`${titleName} 더보기 이동`">view all</nuxt-link>
  </div>
  <div class="item_listwrap mgt50 wrapper" style="aspect-ratio: 343/463;" v-else-if="!initData">
    <div class="main_tit"></div>
  </div>
</template>

<script>
import GoodsList from "@/components/main/mobile/BestGoodsList.vue";
import BestGoods from "../desktop/BestGoods.vue";

export default {
  components: {
    GoodsList,
  },
  extends: BestGoods,
  props: {
    category: {
      type: Object,
      default: () => Array
    },
    menutitle: String
  },

};
</script>